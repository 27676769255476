@import "../node_modules/flag-icons/css/flag-icons.min.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

.list-reason:after {
  content: "";
  height: 4px;
  position: absolute;
  bottom: 0;
  left: -1px;
}

/*.reason-weight-low {border-bottom: 4px solid white;}*/
/*.reason-weight-high {border-bottom: 4px solid gray;}*/
.list-reason {
  position: relative;
}
.terms-of-service-content p {
  margin-top: 20px;
  margin-bottom: 20px;
}

.terms-of-service-content strong {
  color: inherit;
}

